import "#js/components/percentageInput"
import { css, html, LitElement } from "lit"
import { localize } from "#js/components/utils"
import { msg, updateWhenLocaleChanges } from "@lit/localize"
import { setLocale } from "#js/components/lit-i18n"

export class SignUpRate extends LitElement {
  static get properties() {
    return {
      totalEmployeeCount: { type: Number },
      registeredMemberCount: { type: Number },
      monthlyEmployeeChurnRate: { type: Number, reflect: true },
      contractRuntimeMonths: { type: Number },
      onboardingServiceUrl: { type: String },
    }
  }

  constructor() {
    super()
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
  }

  totalEmployeeChurnRate() {
    return Math.pow(1 - this.monthlyEmployeeChurnRate, this.contractRuntimeMonths)
  }

  reachableMembers() {
    return Math.round(this.registeredMemberCount * this.totalEmployeeChurnRate())
  }

  /**
   * Return the percentage of the addressable employee base that is currently reached.
   * @returns {number} - A number between 0 and 1.
   */
  result() {
    return Math.min(1, this.reachableMembers() / this.totalEmployeeCount)
  }

  resultDisplay() {
    return this.result().toLocaleString(globalThis.language, {
      style: "percent",
      maximumFractionDigits: 1,
    })
  }

  static styles = css`
    progress {
      display: block;
      appearance: none;
      height: 1em;
      margin: 0 auto;
      width: 66%;
      align-self: center;
    }

    progress::-webkit-progress-bar {
      background-color: var(--light-grey);
      border-radius: 0.5em;
    }

    progress::-webkit-progress-value {
      background-color: var(--brand-color);
      border-radius: 0.5em;
    }

    progress::-moz-progress-bar {
      border-radius: 0.5em;
      color: var(--brand-color);
    }

    aside {
      font-size: 80%;
    }

    em {
      font-style: normal;
      text-decoration: underline;
      text-decoration-color: var(--brand-color);
      text-decoration-thickness: 0.1em;
      text-underline-offset: 0.2em;
      text-decoration-style: dotted;
    }
  `

  render() {
    const reachableMembers = html`<strong>${localize(this.reachableMembers())}</strong>`
    const result = html`<strong>${localize(this.resultDisplay())}</strong>`
    return html`
        <div style="text-align: center">
            <h3>
              ${this.resultDisplay()} ${msg("sign-up quota")}
              (${reachableMembers} ${msg(html`members`)})
            </h3>
            <progress value="${this.result()}" max="1">
                ${this.resultDisplay()}
            </progress>
        </div>
        <p>
            ${
      msg(
        html`With a sign-up quota of ${result} you are currently reaching about ${reachableMembers} employees.`,
      )
    }
          ${
      msg(
        html`If you would like to increase the registration quota and thus the use of voiio by your employees, you are welcome to use our free <a href="${this.onboardingServiceUrl}">onboarding service</a>.`,
      )
    }
        </p>
    `
  }
}

globalThis.customElements.define("sign-up-rate", SignUpRate)
